import React, { useState } from "react"
import "./assets/styles/_index.scss"
import BcdEmprasaAboutSidebar from "./BcdEmprasaAboutSidebar"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"
import ProptvStand from "../../proptvstand/ProptvStand"

const videoUrlStand = "https://www.propverse.ai/utility/assets/bcd-developer/BCD-Emprasa-video.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")


const videoUrl = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

function BcdEmprasaAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };


    const BroucherDownload = () => {
        const pdfUrl = '/utility/assets/bcd-developer/CorporateBrochure.pdf';

        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'CorporateBrochure.pdf';
        anchor.click();
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    centerMode: true,
                    centerPadding: "0px",
                    slidesToShow: 1,
                },
            },

        ],

    }

    return (
        <>

            <div className="ProjectAboutPageSectionBcd">
                <Container fluid className="BoothBackSection d-none d-md-none">
                    <Link to="/developers/abhee-developer/">
                        <ArrowLeft /> Back
                    </Link>

                    <div className="EnquiryBoothSection d-block d-md-none">
                        <Image src={enquiry} fluid className="" />
                    </div>
                </Container>

                <div className="d-none d-md-block">
                    <BcdEmprasaAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />

                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>

                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/bcd-emprasa-developer/">
                                            <p><Image src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/bcd-emprasa-developer/about/">
                                            <p><Image src={about} fluid className="" /> About&nbsp;Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <Image src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                BCD Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection"  style={{ overflowY: "auto", maxHeight: "400px" }}>
                                            <h4 className='m-0'>BCD Emprasa Developers</h4>
                                            <p className="mb-0">
                                                <p className="AboutExpoPara">BCD is one of the oldest names in the Indian construction and design industry. The company’s
roots date back to 1937. With over seven decades of experience in designing and executing
projects, the company is in a commanding position to economically and efficiently design and
build projects of all types and magnitudes. Be it complete Townships, High rise Buildings, Steel
Plants, Hotels, Power Plants, Cement Plants, Runways, Oil Refineries, Airports, Stadiums,
Public Health and Environmental Projects, Mega Infrastructure Projects: BCD has delivered it
all.
</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">

                                                    <p className="AboutExpoPara">BCD group is a fourth-generation business with zero debt and is led by Mr. Angad Singh Bedi
                                                    who is the Managing Director.</p>

                                                    <p className="AboutExpoPara">It has completed over 100 projects covering 70 million sq. ft. Some of the most prestigious
projects done by BCD include the key iconic structures in India like The Supreme Court, High
Court New Delhi, Udyog Bhawan, The Oberoi Hotel (Delhi), Qutab Hotel New Delhi, Bhilai
Hotel, Steel Plants in Bhilai, Rourkela, Vishakhapatnam, Durgapur & Bokaro, Egyptian
Embassy, Malaysian High Commission, Central Board of Revenue Building, Bank Of Baroda,
Power Plants, Runways amongst others. It has also completed many International projects
around the world in places like Iraq, Saudi Arabia, Mauritius, and Nepal to name a few.</p>

                                                    <p className="AboutExpoPara">Known for following International standards of quality, safety, and strict timelines, BCD
possesses the requisite expertise and credibility which enables it to keep projects ahead of
schedule by virtue of modern construction technologies along with abundant manpower
available at their command. With specialized experience in the construction industry for more
than 80 years, BCD has carved a niche in the industry as one of the leading Civil Contractors of
the country.</p>

<p className="AboutExpoPara">Currently, BCD is constructing over 6 Million sq. ft in India for MNC’s, Fortune 500 companies,
HNI’s, and Billion Dollar Groups. Other than the above, it has developed 3 Million sq. ft of real
estate for BCD owned projects. This has also led the company to come forth as one of the
exceptional names in the Indian real estate industry.</p>



<h4 className='m-0'>About Kaman Holdings:</h4>

                    <p className="AboutExpoPara">Kaman Holding Pvt Ltd, a part of Kaman Group of companies and joint venture partner
Embassy Golflinks Park led by Mr. Sunil Gupta, embodies robust family values and traditions for
generations.Their commitment to excellence spans across diverse sectors such as land
aggregation, construction, mining, precious metals, and tourism. With a visionary approach to
innovation, Kaman Holding Pvt Ltd consistently strives for success. Their dedication to quality
and unwavering standards sets them apart in the industry. Under Mr. Sunil Gupta's leadership,
they continue to excel and make significant contributions. This impressive company stands as a
beacon of integrity and forward-thinking in today's business landscape.</p>

                                                </p>

                                                
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button onClick={BroucherDownload}>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                            videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default BcdEmprasaAbout